import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import ServiceBox from "../components/ServiceBox"
import Button from "../components/Button"
import CarouselClient from "../components/CarouselClients"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IndexPage = props => {
  const pageData = props.data.wpPage
  const homeFields = props.data.wpPage.homeFields
  const seoData = props.data.wpPage.seo
  console.log("seo", seoData);
  const settings2 = {
    dots: false,
    centerMode: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  }

  return (
    <Layout extraClass="home" pageId={pageData.databaseId}>
      <Seo
        title={seoData.title}
        description={seoData.metaDesc}
        canonical={seoData.canonical}
        keywords={seoData.metaKeywords}
      />
      <div className="banner">
        <GatsbyImage
          image={homeFields.hero.bannerImage.localFile.childImageSharp.gatsbyImageData}
          alt={homeFields.hero.bannerImage.altText}
        />
        <div className="container">
          <div className="copy">
            <h1>
              <span className="italic-red">{homeFields.hero.city}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: homeFields.hero.headline,
                }}
              />
            </h1>
            <p className="introtxt">{homeFields.hero.copy}</p>
            <Button
              link={homeFields.hero.pageLink.link}
              title={homeFields.hero.buttonName}
            />
          </div>
        </div>
      </div>
      <div className="services">
        <div className="container">
          <div className="copy">
            <h2 className="white">{homeFields.services.headline}</h2>
            <p className="white">{homeFields.services.copy}</p>
          </div>
          {props.data.allWpService.edges.map(service => (
            <ServiceBox
              demand={service.node.singleService.isHighDemand}
              link={service.node.uri}
              icon={service.node.singleService.icon.sourceUrl}
              title={service.node.singleService.panelHeadline}
              copy={service.node.singleService.smallBlurb}
            />
          ))}
        </div>
      </div>
      <div className="promise">
        <div className="container">
          <GatsbyImage
            image={homeFields.imageWithText.image.localFile.childImageSharp.gatsbyImageData}
            alt={homeFields.imageWithText.image.altText}
            className="cody-arms"
          />
          <div className="copy">
            <h2>{homeFields.imageWithText.headline}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: homeFields.imageWithText.copy,
              }}
            />
            <Button link="/certifications" title="View Certifications" />
          </div>
        </div>
      </div>
      <div className="client-carousel">
        <div className="contain">
          <CarouselClient />
        </div>
      </div>
      <div className="testimonialCTA">
        <div className="container">
          <div className="copy">
            <h2>{homeFields.imageCarousel.headline}</h2>
            <p>{homeFields.imageCarousel.copy}</p>
            <Button link={homeFields.imageCarousel.pageLink.link} title={homeFields.imageCarousel.buttonName} />
          </div>
        </div>
      </div>
      <div className="image-carousel">
        <Slider {...settings2}>
          {pageData.carousel.carousel.map(photo => (
            <div key={photo.image.sourceUrl}>
              <GatsbyImage
                image={photo.image.localFile.childImageSharp.gatsbyImageData}
                alt={photo.image.altText}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Layout>
  )
}

export default IndexPage

export const homeQuery = graphql`
  query homePageQuery {
    wpPage(databaseId: { eq: 7 }) {
      databaseId
      seo {
        title
        canonical
        metaDesc
        metaKeywords
      }
      homeFields {
        hero {
          bannerImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          buttonName
          city
          copy
          headline
          pageLink {
            ... on WpPage {
              link
            }
          }
        }
        imageCarousel {
          copy
          headline
          pageLink {
            ... on WpPage {
              link
            }
          }
          buttonName
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            altText
          }
        }
        imageWithText {
          copy
          headline
          pageLink {
            ... on WpPage {
              id
              link
            }
          }
          pageName
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        services {
          copy
          headline
        }
      }
      carousel {
        carousel {
          image {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    allWpService(limit: 5, sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          uri
          singleService {
            smallBlurb
            icon {
              altText
              sourceUrl
            }
            panelHeadline
            isHighDemand
          }
        }
      }
    }
  }
`
