import React from "react"

const ServiceBox = ({icon, title, copy, link, demand}) => {

  return(
    <a href={link} className="service-box hvr-bob">
        {!!demand && (
        <div className="demand">
            High Demand
        </div>
        )}
        
        <img src={icon} alt="icon" />
        <h3>{title}</h3>
        <p>{copy}</p>
        <p className="red hover hover-1">Learn More</p>
    </a>
  )
}

export default ServiceBox